import axios from 'axios';
import { SEND_EMAIL_OTP_SUCCESS, VERIFY_EMAIL_OTP_SUCCESS } from '../constants/ActionType';
import { toast } from 'react-toastify';

export const sendEmailOTP = (email) => async (dispatch) => {
  try {
    const response = await axios.post(
      'https://saty-world-backend-service-9c842ce66731.herokuapp.com/saty-world-backend-service/users/generate-token',
      `email=${email}`,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    // toast.success(response.data.message)
    toast.success('OTP send successfully.');   

  } catch (error) {
    toast.error('Error sending OTPs:',error.message);   

    console.error('Error sending OTP:', error.message);
  }
};

export const verifyEmailOTP = (email, token) => async (dispatch) => {
  try {
    const response = await axios.post(
      'https://saty-world-backend-service-9c842ce66731.herokuapp.com/saty-world-backend-service/users/validate-token',
      `email=${email}&token=${token}`,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    // toast.success(response.data.message)    
      toast.success('Verify successfully.');   
  } catch (error) {
    console.error('Error verifying OTP:', error.message);
  }
};
