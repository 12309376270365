import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { NavDropdown } from "react-bootstrap";

const Navbar = ({ toggle }) => {
  return (
    <nav>
      <h3 className="link">SATYWORLD</h3>
      <div className="menu-items">
        <Link className="link" to={"/"}>
          Home
        </Link>
        <Link className="link" to="/about">
          About
        </Link>
        <NavDropdown
          className="link"
          title="Truth"
          id="navbarScrollingDropdown"
        >
          <NavDropdown.Item href="#">Action</NavDropdown.Item>
          <NavDropdown.Item href="#">Find truth about person</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#">Something else here</NavDropdown.Item>
        </NavDropdown>
      </div>
      <div className="button">
        <Link to="/login" className="btn btn-light"> SignUp </Link>
      </div>
      <div className="mobile-menu-icon">
        <FaBars onClick={toggle} />
      </div>
    </nav>
  );
};

export default Navbar;
