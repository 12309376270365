import {
    SEND_COMPLAINT_REQUEST,
    SEND_COMPLAINT_SUCCESS,
    SEND_COMPLAINT_FAILURE,
  } from '../constants/ActionType';
  
  const initialState = {
    loading: false,
    success: null,
    error: null,
  };
  
  const complaintReducer = (state = initialState, action) => {
    switch (action.type) {
      case SEND_COMPLAINT_REQUEST:
        return { ...state, loading: true, success: null, error: null };
      case SEND_COMPLAINT_SUCCESS:
        return { ...state, loading: false, success: action.payload, error: null };
      case SEND_COMPLAINT_FAILURE:
        return { ...state, loading: false, success: null, error: action.payload };
      default:
        return state;
    }
  };
  
  export default complaintReducer;
  