import React, { useState } from 'react';
import './Emailverify.css'
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmailOTP, verifyEmailOTP } from '../../redux/actions/verificationActions';

const Emailverify = () => {
  const [isSendCode, setIsSendCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendEmailOTPSuccess = useSelector((state) => state.sendEmailOTPSuccess);
  const verifyEmailOTPSuccess = useSelector((state) => state.verifyEmailOTPSuccess);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (email.trim() === '') {
      toast.error('Please enter your email.');
      return;
    }
    try {
      setIsSendCode(true);
      dispatch(sendEmailOTP(email));
    } catch (error) {
      toast.error('An error occurred while sending OTP. Please try again.');
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    if (email.trim() === '' || verificationCode.trim() === '') {
      toast.error('Please fill in both fields.');
      return;
    }
    try {
      await dispatch(verifyEmailOTP(email, verificationCode));
      toast.success('Verification successful.'); 
      navigate('/complent'); // Redirect to '/report' upon successful verification
    } catch (error) {
      toast.error('An error occurred while verifying OTP. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsSendCode(false);
    setVerificationCode('');
  };

  return (
    <div id="login-form">
      <h1>Verify</h1>
      <form>
        <label htmlFor="email">Email address:</label>
        <input
          type="text"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {isSendCode && (
          <div>
            <label htmlFor="verification-code">Verification code:</label>
            <input
              type="text"
              id="verification-code"
              name="verification-code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button type="submit"  className="btn btn-success mb-2" onClick={handleVerify}>
              Verify
            </button>
          </div>
        )}
        <button
          type="button"
          className={`btn ${isSendCode ? 'btn-cancel' : 'btn-send-otp'}`}
          onClick={isSendCode ? handleCancel : handleSendOtp}
        >
          {isSendCode ? 'Cancel' : 'Send OTP'}
        </button>
      </form>
      <ToastContainer/>
    </div>
  );
};

export default Emailverify;
