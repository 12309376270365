import axios from 'axios';
import {
  SEND_COMPLAINT_REQUEST,
  SEND_COMPLAINT_SUCCESS,
  SEND_COMPLAINT_FAILURE
} from '../constants/ActionType'
import { toast } from 'react-toastify';

export const sendComplaint = (complaintData) => async (dispatch) => {
  dispatch({ type: SEND_COMPLAINT_REQUEST });

  try {
    const response = await axios.post(
      'https://saty-world-backend-service-9c842ce66731.herokuapp.com/saty-world-backend-service/satyReporting/abputPerson',
      complaintData
    );
    dispatch({ type: SEND_COMPLAINT_SUCCESS, payload: response.data });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: SEND_COMPLAINT_FAILURE, payload: error.message });
  }
};
