import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';
import {Row, Col, Form, Button } from 'react-bootstrap';

function Home() {
  const navigate = useNavigate();
    navigate('/login');
  return (
    <section>
      <div className={styles.intro}>
        <div className={styles.section}>
            <Row className="justify-content-center">
              <Col md={6} className={`intro-text ${styles.overlay} mb-4 mb-md-0`}>
                <Row className={styles.row}>
                  <h2 data-aos="fade-down">Find the truth about someone</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam, perspiciatis! Animi esse aspernatur sed doloribus?</p>
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form>
                </Row>
                <Row className={styles.row}>
                  <h2 data-aos="fade-down">Report truth about anyone</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias repudiandae quasi nam ratione, obcaecati deserunt.</p>
                  <Link to="/login" className="btn btn-success" id="get-started">
                    Report Now
                  </Link>
                </Row>
              </Col>

              <Col md={6} className="intro-text">
                <Row className={styles.row}>
                <h2 data-aos="fade-down">Report truth about anyone</h2>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsam, inventore.</p>
                <Button className="btn btn-custom btn-lg page-scroll">
                 Confess now
                </Button>
                </Row>
              </Col>
            </Row>        
        </div>
      </div>
    </section>
  );
}
export default Home;
