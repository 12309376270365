// rootReducer.js

import { combineReducers } from 'redux';
import verificationReducer from '../reducers/verificationReducer';
import complaintReducer from '../reducers/complaintReducer';


const rootReducer = combineReducers({
  verification: verificationReducer,
  complentSend:complaintReducer,

});

export default rootReducer;
