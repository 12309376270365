import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element }) => {
  const verifyEmailOTPSuccess = useSelector(
    (state) => state.verifyEmailOTPSuccess
  );

  return verifyEmailOTPSuccess?.success_status ? (
    // Render the provided element if authenticated
    element
  ) : (
    // Redirect to the login page if not authenticated
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
