import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { sendComplaint } from '../../redux/actions/complaintActions';
import './complentForm.css'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

function ComplentFrom() {
  const [isChecked, setIsChecked] = useState(false)
  const [complaintData, setComplaintData] = useState({
    name: '',
    mobile_number: '',
    email: '',
    instagram_handle: '',
    linkedin_url: '',
    facebook_handle: '',
    date_of_birth: '',
    place_of_living: '',
    city: '',
    state: '',
    country: '',
    report_details: '',
  });

  const dispatch = useDispatch();
  const complaintResponse = useSelector((state) => state.complentSend);
  console.log("complaintResponse", complaintResponse)
  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      dispatch(sendComplaint(complaintData));
    } catch (error) {
      console.log("error", error)
      toast.error('Error sending complaint. Please try again.');
    }
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  }
  return (
    <div className='row' >
      <div className='col-md-12'>
        <div id="Complaint-form">
          <h1>Complaint</h1>
          <form>
            <label>Person Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={complaintData.name}
              onChange={(e) =>
                setComplaintData({ ...complaintData, name: e.target.value })
              }
            />
            <label>Mobile Number:</label>
            <input
              type="text"
              id="email"
              name="mobile_number"
              value={complaintData.mobile_number}
              onChange={(e) =>
                setComplaintData({ ...complaintData, mobile_number: e.target.value })
              }
            />
            <label>Email :</label>
            <input
              type="text"
              id="email"
              name="email"
              value={complaintData.email}
              onChange={(e) =>
                setComplaintData({ ...complaintData, email: e.target.value })
              }
            />
            <label>instagram handle:</label>
            <input
              type="text"
              id="email"
              name="instagram_handle"
              value={complaintData.instagram_handle}
              onChange={(e) =>
                setComplaintData({ ...complaintData, instagram_handle: e.target.value })
              }
            />
            <label>linkedin url:</label>
            <input
              type="text"
              id="linkedin_url"
              name="linkedin_url"
              value={complaintData.linkedin_url}
              onChange={(e) =>
                setComplaintData({ ...complaintData, linkedin_url: e.target.value })
              }
            />
            <label>facebook handle:</label>
            <input
              type="text"
              id="email"
              name="facebook_handle"
              value={complaintData.facebook_handle}
              onChange={(e) =>
                setComplaintData({ ...complaintData, facebook_handle: e.target.value })
              }
            />
            <label htmlFor="">date of birth</label>
            <input type="text"
              id='date_of_brith'
              name='date' />
            <label htmlFor="">place of living</label>
            <input type="text"
              id='place_of_living'
              name="name"
              value={complaintData.place_of_living}
              onChange={(e) =>
                setComplaintData({ ...complaintData, place_of_living: e.target.value })
              }
            />
            <label htmlFor="">State</label>
            <input type="text"
              id='state'
              name="name"
              value={complaintData.state}
              onChange={(e) =>
                setComplaintData({ ...complaintData, state: e.target.value })
              }
            />
            <label htmlFor="">country</label>
            <input type="text"
              id='country'
              name="name"
              value={complaintData.country}
              onChange={(e) =>
                setComplaintData({ ...complaintData, country: e.target.value })
              }
            />
            <label>Report Details</label>
            <textarea name=""
              type='text'
              id="Complaint-form textarea"
              value={complaintData.report_details}
              onChange={(e) =>
                setComplaintData({ ...complaintData, report_details: e.target.value })
              }></textarea>

            <div>
              <label>
                Do you want to upload supporting documents?
              </label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />

              {isChecked && (
                <div>
                  <p>Files must be less than 4 MB.</p>
                  <p>Allowed file types: pdf, doc, docx, xls, xlsx.</p>
                  <p>Do not include any personal or confidential information, such as your social security number or bank account number.</p>
                  <p>If you have additional documentation, list and detail them in your complaint.</p>
                  <div>
                    <label>Upload supporting documents</label>
                    <input type="file" multiple />
                  </div>
                </div>
              )}
            </div>
            <button
              type="button"
              className="btn btn-success mt-3"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ComplentFrom