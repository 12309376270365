import { SEND_EMAIL_OTP_SUCCESS, VERIFY_EMAIL_OTP_SUCCESS } from '../constants/ActionType';

const initialState = {
  sendEmailOTPSuccess: "",
  verifyEmailOTPSuccess: "",
};

const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        sendEmailOTPSuccess: action.payload,
      };
    case VERIFY_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        verifyEmailOTPSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default verificationReducer;
