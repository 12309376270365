import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Navbar from './components/Header/Navbar';
import configureStore from './redux/store/configureStore'
import ComplentFrom from './components/Form/ComplentForm'
import Emailvarify from './components/EmailVerify/Emailverify'
import PrivateRoute from './components/CustomComponents/PrivateRoute';

function App() {
  return (
    <>
      <Provider store={configureStore}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/login' element={<Emailvarify />} />
            <Route path="/complent" element={<PrivateRoute element={<ComplentFrom />} />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}
export default App;
